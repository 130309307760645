import { useCallback, useEffect, useState } from "react";
import NetworkApi from "../network/networkApi";
interface ContentData {
  result: any[];
  loading: boolean;
}
const useSupportAvailableTypes = (): [ContentData, () => void] => {
  const [content, changeContent] = useState<ContentData>({
    loading: false,
    result: [],
  });

  useEffect(() => {
    loadContent();
  }, []);

  const loadContent = useCallback(async () => {
    changeContent({
      ...content,
      loading: true,
    });
    let response = await NetworkApi.requestGet("/api/support/available/types");
    if (response.resultCode === 200) {
      changeContent({
        loading: false,
        result: response.result.rows || [],
      });
    }
  }, [content]);

  return [content, loadContent];
};
export default useSupportAvailableTypes;

import { useEffect, useState } from "react";
import { Button, Col, DatePicker, Descriptions, Form, Row, Table } from "antd";
import useFarmerPlantWarehouse from "../../../hooks/useFarmerPlantWarehouse";
import { SearchOutlined } from "@ant-design/icons";

const FarmerWarehousePlant = ({ ownerId }: { ownerId: string }) => {
  const [plant, , filter, changeFilter] = useFarmerPlantWarehouse();
  const [page, setPage] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(10);

  useEffect(() => {
    if (ownerId) {
      changeFilter({
        ...filter,
        ownerId,
      });
    }
  }, [ownerId]);

  const columnsT = [
    {
      title: "№",
      render: (a: any, b: any, index: any) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Таримлын төрөл",
      dataIndex: "sort",
      render: (sort: any) => (sort ? `${sort.plant.type.name}` : "-"),
    },
    {
      title: "Таримал",
      dataIndex: "sort",
      render: (sort: any) => (sort ? `${sort.plant.name}` : "-"),
    },
    {
      title: "Таримлын сорт",
      dataIndex: "sort",
      render: (sort: any) => (sort ? `${sort.name}` : "-"),
    },
    {
      title: "Хаанаас",
      render: (itm: any) => {
        if (itm.type == "harvest") {
          return `НТД-${itm.farmlandPlant?.usage?.farmland?.posNo}`;
        }
        if (itm.type == "import") {
          return itm.country?.name;
        }
        if (itm.type == "natural") {
          return `${itm.aimag?.name}-${itm.sum?.name}`;
        }
      },
    },
    {
      title: "Төрөл",
      render: (itm: any) => {
        if (itm.type == "harvest") {
          return "Таримал";
        }
        if (itm.type == "import") {
          return "Импорт";
        }
        if (itm.type == "natural") {
          return "Байгалийн";
        }
      },
    },
    {
      title: "Хэмжээ",
      render: (itm: any) =>
        `${itm.amount} ${itm.sort?.unit ? itm.sort.unit.name : ""}`,
    },
    {
      title: "Үлдэгдэл",
      render: (itm: any) =>
        `${itm.remaining} ${itm.sort?.unit ? itm.sort.unit.name : ""}`,
    },
    {
      title: "Огноо",
      dataIndex: "date",
      key: "date",
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Form
            layout="inline"
            onFinish={(filter: any) => {
              if (filter.year) {
                changeFilter({
                  ...filter,
                  year: filter.year.format("YYYY"),
                });
              }
            }}
          >
            <Row gutter={[0, 16]} style={{ width: "100%" }}>
              <Col flex="none">
                <Form.Item name="year">
                  <DatePicker picker="year" placeholder="Он" />
                </Form.Item>
              </Col>
              <Col flex="none">
                <Button
                  htmlType="submit"
                  type={"primary"}
                  style={{ marginRight: 16 }}
                >
                  <SearchOutlined />
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row style={{ marginTop: 15, marginBottom: 15 }}>
        <Col span={24}>
          <Descriptions title="" style={{ marginTop: 15 }}>
            <Descriptions.Item label="Нийт">{plant.count}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={24}>
          <Table
            scroll={{ x: true }}
            style={{ width: "100%" }}
            pagination={{
              current: plant.page,
              pageSize: plant.pageSize,
              total: plant.count,
              onChange(current, pageSize) {
                setPage(current);
                setPageSize(pageSize);
              },
            }}
            onChange={(p) => {
              changeFilter({
                ...filter,
                page: p.current || 1,
                pageSize: p.pageSize || 10,
              });
            }}
            loading={plant.loading}
            columns={columnsT}
            dataSource={plant.result}
          />
        </Col>
      </Row>
    </>
  );
};

export default FarmerWarehousePlant;

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import NetworkApi from "../../network/networkApi";
import BreadMe from "../lib/Breadme";
import { formatNumberWithComma } from "../../helpers/number.helper";
import {
  SUPPORT_STATUS,
  SUPPORT_STATUS_REF,
  SUPPORT_TYPE,
  SUPPORT_TYPE_REF,
} from "../../enums/support.enum";
import moment from "moment";
import SupportOthersDetail from "./atoms/SupportDetail";
import {
  EditOutlined,
  FileTextOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import UserContext from "../../providers/UserContext";
import { Link } from "react-router-dom";
import AddressSelect from "../lib/AddressSelect";

const SupportOthersList = () => {
  const [content, changeContent] = useState<{
    loading: boolean;
    page: number;
    count: number;
    filter: any;
    result: any[];
    fpage: number;
    tpage: number;
  }>({
    loading: false,
    page: 1,
    count: 0,
    filter: {},
    result: [],
    fpage: 0,
    tpage: 0,
  });

  const [isInitialize, setIsInitialize] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  const selectYears = useMemo(() => {
    const years = [];
    const currentYear = moment().year();
    for (let i = 2023; i <= currentYear; i++) {
      years.push(i);
    }
    return years;
  }, []);
  const userOrganizationId: number = useMemo(() => {
    console.log(user);
    if (user?.organizations?.length > 0) {
      return user.organizations[0].id;
    }
    return 0;
  }, [user]);

  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet("/api/support/request/list", {
      ...content.filter,
      page: content.page,
      type: SUPPORT_TYPE.TECHNIC,
    });
    if (response.resultCode === 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          page: response.result.page,
          count: response.result.count,
          result: response.result.rows ?? [],
          fpage: response.result.fpage ?? 0,
          tpage: response.result.tpage ?? 0,
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content]);

  const changeFilter = (filter: any) => {
    changeContent({ ...content, filter });
  };

  useEffect(() => {
    loadContent();
  }, [content.filter, content.page]);

  useEffect(() => {
    if (!isInitialize && content.tpage > 0 && content.page !== content.tpage) {
      changeContent({
        ...content,
        page: content.tpage,
      });
      setIsInitialize(true);
    }
  }, [content.tpage, isInitialize]);

  const [showDetail, setShowDetail] = useState({
    id: 0,
    visible: false,
  });

  const supportInfo = [
    {
      title: "№",
      dataIndex: "id",
      render: (v: any, r: any, index: number) => (
        <div
          className={`${
            userOrganizationId === r.organization.id && "special-td"
          }`}
        >
          {index + 1}
        </div>
      ),
    },
    {
      title: "Хүсэлт илгээсэн огноо",
      dataIndex: "sentDate",
      render: (value: string) =>
        `${moment(value).format("YYYY-MM-DD HH:mm:ss")}`,
    },
    {
      title: "Овог нэр",
      dataIndex: "user",
      render: (value: any) => `${value.lastName} ${value.firstName}`,
    },
    {
      title: "Утас",
    },
    {
      title: "РД",
      dataIndex: ["user", "rd"],
    },
    {
      title: "Аймаг",
      dataIndex: ["user", "aimag", "name"],
    },
    {
      title: "Сум",
      dataIndex: ["user", "sum", "name"],
    },
    {
      title: "Дэмжлэгийн төрөл",
      dataIndex: "type",
      render: (value: string) => SUPPORT_TYPE_REF[value],
    },
    {
      title: "Бүтээгдэхүүний хэмжээ",
      dataIndex: "supportItems",
      render: (value: any[]) => value.length,
    },
    {
      title: "Үнийн дүн",
      dataIndex: "totalAmount",
      render: (value: number) => `${formatNumberWithComma(value ?? 0)} ₮`,
    },
    {
      title: "Дэмжлэг бодогсон дүн",
      dataIndex: "issuedAmount",
      render: (value: number) => `${formatNumberWithComma(value ?? 0)} ₮`,
    },
    {
      title: "Олгосон огноо",
      dataIndex: "issuedDate",
    },
    {
      title: "Жагсаалтад орсон эсэх?",
      dataIndex: "inListed",
      render: (value: boolean) => {
        if (value) {
          return <Tag color={"processing"}>Орсон</Tag>;
        }

        return <>Ороогүй</>;
      },
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      render: (value: string) => `${SUPPORT_STATUS_REF[value]}`,
    },
    {
      title: "Үйлдэл",
      width: 220,
      render: (itm: any) => (
        <Space size={10}>
          <Button
            className="support-action-btn"
            onClick={() => setShowDetail({ id: itm.id, visible: true })}
            icon={<FileTextOutlined />}
            type={"primary"}
            ghost
            size={"small"}
          />
          {/* {(itm.status === SUPPORT_STATUS.DRAFT ||
            itm.status === SUPPORT_STATUS.RETURNED) && (
            <Link to={`/support-others/edit/${itm.id}`}>
              <Button
                className="support-action-btn"
                icon={<EditOutlined />}
                type={"primary"}
                ghost
                size={"small"}
              />
            </Link>
          )} */}
          {(itm.status === SUPPORT_STATUS.DRAFT ||
            itm.status === SUPPORT_STATUS.RETURNED) && (
            <Button
              className="support-action-btn"
              icon={<EditOutlined />}
              type={"primary"}
              ghost
              size={"small"}
              onClick={() => {
                Modal.warning({
                  title: "Анхааруулга",
                  content: (
                    <div>
                      <div>
                        Төсвийн санхүүжилт дууссан тул техникийн татаас олгох
                        2024 оны хүсэлт хүлээн авахыг зогсоосон.
                      </div>
                      <div style={{ textAlign: "center", padding: "3px 0px" }}>
                        <span>
                          Утас:{" "}
                          <a href="tel:51263408" style={{ color: "#1890ff" }}>
                            51263408
                          </a>
                        </span>
                      </div>
                      <div style={{ textAlign: "center", padding: "3px 0px" }}>
                        ХХААХҮЯ
                      </div>
                    </div>
                  ),
                  okText: "За",
                });
              }}
            />
          )}
        </Space>
      ),
    },
  ];
  return (
    <>
      <BreadMe title={["Дэмжлэгийн сан", "Техникийн жагсаалт"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        {showDetail.visible && (
          <SupportOthersDetail
            visible={showDetail.visible}
            id={showDetail.id}
            onHide={() => setShowDetail({ id: 0, visible: false })}
          />
        )}
        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Form
              layout="inline"
              onFinish={(filter: {
                aimagId: string;
                sumId: string;
                name: string;
                userRd: string;
                inListed: string;
                status: string;
                year: string;
              }) => {
                changeFilter(filter);
              }}
            >
              <Row gutter={[0, 16]} style={{ width: "100%" }}>
                <Col flex="none">
                  <Form.Item name="name">
                    <Input placeholder={"Овог нэрээр хайх"} />
                  </Form.Item>
                </Col>
                <Col flex="none">
                  <Form.Item name="userRd">
                    <Input placeholder={"РД-аар хайх"} />
                  </Form.Item>
                </Col>
                <Col flex="none">
                  <Row gutter={[0, 16]}>
                    <AddressSelect hideLabel={true} ignoreBag={true} />
                  </Row>
                </Col>
                <Col flex="180px">
                  <Form.Item name="inListed">
                    <Select allowClear placeholder="Жагсаалтад орсон эсэх">
                      <Select.Option value="1">Орсон</Select.Option>
                      <Select.Option value="2">Ороогүй</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col flex="180px">
                  <Form.Item name="year">
                    <Select allowClear placeholder="Он">
                      {selectYears.map((item: number) => (
                        <Select.Option key={`y-${item}`} value={`${item}`}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col flex="250px">
                  <Form.Item name="status">
                    <Select allowClear placeholder="Төлөв">
                      {Object.values(SUPPORT_STATUS).map((item: string) => (
                        <Select.Option key={`s-${item}`} value={`${item}`}>
                          {SUPPORT_STATUS_REF[item]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col flex="none">
                  <Button
                    htmlType="submit"
                    type={"primary"}
                    style={{ marginRight: 16 }}
                  >
                    <SearchOutlined />
                  </Button>
                </Col>
                {/* {USER_ROLE.CUSTOMER !== user.role?.cd && (
                  <Col flex="none">
                    <Button
                      target="blank"
                      href={`${config.get(
                        "apiUrl"
                      )}/api/warehouse/list/download?${querystring.stringify(
                        content.filter
                      )}`}
                      type={"primary"}
                    >
                      <DownloadOutlined />
                      Татах
                    </Button>
                  </Col>
                )} */}
              </Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              className="support-table"
              rowKey={"id"}
              scroll={{ x: true }}
              pagination={{
                current: content.page,
                total: content.count,
              }}
              onChange={(p) => {
                changeContent({
                  ...content,
                  page: p.current || 1,
                });
              }}
              loading={content.loading}
              columns={supportInfo}
              dataSource={content.result ?? []}
              onRow={(record, rowIndex) => {
                let className: string = "";
                if (record.inListed) {
                  className += " success-row";
                }
                return {
                  className,
                };
              }}
            />
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};

export default SupportOthersList;

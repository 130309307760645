import { useCallback, useEffect, useState } from "react";
import NetworkApi from "../network/networkApi";
interface ContentData {
  result: any[];
  loading: boolean;
  count: 0;
  page: 1;
  pageSize: 10;
}

type FilterType = {
  year?: string;
  ownerId?: string;
  page: number;
  pageSize: number;
};

const useFarmerPlantWarehouse = (): [
  ContentData,
  () => void,
  FilterType,
  (filter: FilterType) => void
] => {
  const [filter, changeFilter] = useState<FilterType>({
    page: 1,
    pageSize: 10,
  });
  const [content, changeContent] = useState<ContentData>({
    loading: false,
    result: [],
    count: 0,
    page: 1,
    pageSize: 10,
  });
  useEffect(() => {
    loadContent();
  }, [filter]);
  const loadContent = useCallback(async () => {
    changeContent({
      ...content,
      loading: true,
    });
    let response = await NetworkApi.requestGet(
      "/api/warehouse/plant/list",
      filter
    );
    changeContent({
      loading: false,
      count: response.result.count,
      page: response.result.page,
      pageSize: response.result.pageSize,
      result: response.result?.rows || [],
    });
  }, [content, filter]);

  return [content, loadContent, filter, changeFilter];
};
export default useFarmerPlantWarehouse;

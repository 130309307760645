import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Upload,
  Image,
  Tag,
  DatePicker,
  Popconfirm,
  Spin,
  InputNumber,
} from "antd";
import {
  SearchOutlined,
  CloudDownloadOutlined,
  PlusSquareOutlined,
  EditOutlined,
  DeleteOutlined,
  FileTextOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import NetworkApi from "../../network/networkApi";
import BreadMe from "../lib/Breadme";
import UserContext from "../../providers/UserContext";
import useChemicalTypeList from "../../hooks/useChemicalTypeList";
import useChemical from "../../hooks/useChemical";
import config from "../../config";
import useChemicalLicenseList from "../../hooks/useChemicalLicenseList";
import moment from "moment";
import { EditableChemicalLicenseTable } from "./components/EditablePlanFarmLandTable";
import { USER_ROLE } from "../../enums/user.enum";
import { debounce } from "lodash";
import useChemicalTypeFileList from "../../hooks/useChemicalTypeFileList";
const host = config.get("apiUrl");
const { Option } = Select;

const { Dragger } = Upload;
interface EditState {
  visible: boolean;
  edit?: any;
}
const TagCreat = ({ cs }: any) => {
  switch (cs) {
    case "created":
      return <Tag color={"processing"}>Үүсгэсэн</Tag>;
    case "pending":
      return <Tag color={"default"}>Хүлээгдэж буй</Tag>;
    case "rejected":
      return <Tag color={"error"}>Татгалзсан</Tag>;
    case "active":
      return <Tag color={"success"}>Баталсан</Tag>;
    case "accepted":
      return <Tag color={"success"}>Зөвшөөрсөн</Tag>;
    case "deleted":
      return <Tag color={"warning"}>Устгасан</Tag>;
    default:
      return <Tag color={"default"}>Хүлээгдэж буй</Tag>;
  }
};

const Statuses = [
  {
    id: "created",
    name: "Үүсгэсэн",
  },
  {
    id: "pending",
    name: "Хүлээгдэж буй",
  },
  {
    id: "rejected",
    name: "Татгалзсан",
  },
  {
    id: "accepted",
    name: "Зөвшөөрсөн",
  },
  {
    id: "active",
    name: "Баталсан",
  },
  {
    id: "deleted",
    name: "Устгасан",
  },
];
const Chemical = () => {
  const { user } = useContext(UserContext);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [typeList] = useChemicalTypeList();
  const [content, loadContent, changeFilter, filter] = useChemicalLicenseList();
  const license = useMemo(() => {
    let result = {
      sale: false,
      import: false,
    };
    if (
      user.organizations &&
      user.organizations.length > 0 &&
      user.organizations[0].license &&
      user.organizations[0].license.length > 0
    ) {
      result = {
        sale: user.organizations[0].license[0].salesLicense,
        import: user.organizations[0].license[0].importLicense,
      };
    }
    return result;
  }, [user]);
  useEffect(() => {
    changeFilter({ t: "all" });
  }, []);
  const [addEdit, setAddEdit] = useState<EditState>({
    visible: false,
  });
  const [detail, setDetail] = useState<EditState>({
    visible: false,
  });
  const [visibleDetails, setVisibleDetails] = useState<boolean>(false);

  useEffect(() => {
    if (!visibleDetails) {
      loadContent();
    }
  }, [visibleDetails]);

  const deleteItem = useCallback(async (id: any) => {
    let response = await NetworkApi.requestGet(
      `/api/chemical/license/delete/${id}`
    );
    if (response.resultCode == 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, []);

  const columns = [
    {
      title: "Хуулийн этгээд",
      render: (itm: any) => itm.organization?.name,
    },
    {
      title: "Зөвшөөрлийн төрөл",
      dataIndex: "type",
      render: (itm: any) => (itm == "sale" ? "Борлуулах" : "Импортлох"),
    },
    {
      title: "Бодис, бордооны төрөл",
      render: (itm: any) => itm.chemical?.type?.name,
    },
    {
      title: "Худалдааны нэр",
      render: (itm: any) => itm.chemical?.technicalName,
    },
    {
      title: "ОУ-ын бүртгэлийн CAS № ",
      render: (itm: any) => itm.chemical?.registrationNumber,
    },
    {
      title: "Илгээсэн огноо",
      dataIndex: "createdAt",
      render: (item: string) => moment(item).format("YYYY/MM/DD"),
    },
    {
      title: "Хүсэлтийн хэмжээ",
      render: (itm: any) =>
        `${itm.requestedAmount || "0"}${itm.chemical?.unit?.name}`,
    },
    {
      title: "Зөвшөөрсөн хэмжээ",
      render: (itm: any) =>
        `${itm.offerAmount || "0"}${itm.chemical?.unit?.name}`,
    },
    {
      title: "Орлогод авсан хэмжээ",
      render: (itm: any) =>
        `${itm.totalWarehouse || "0"}${itm.chemical?.unit?.name}`,
    },
    {
      title: "Эцсийн үлдэгдэл",
      render: (itm: any) =>
        `${itm.remaining || "0"}${itm.chemical?.unit?.name}`,
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      render: (value: string, record: any) => {
        if (
          value === "pending" &&
          record.vAgro === "accepted" &&
          record.pAgro === "accepted"
        ) {
          return <TagCreat cs="accepted" />;
        }
        return <TagCreat cs={value} />;
      },
    },
    {
      title: "Үйлдэл",
      render: (itm: any) => (
        <Space size={10}>
          {user.role.cd == "HEAD_OF_OFFICE" ||
            user.role.cd == "SPECIALIST" ||
            user.role.cd == "ADMIN" ||
            (user.role.cd == "CUSTOMER" && (
              <>
                {itm.status != "active" && (
                  <Button
                    type={"primary"}
                    size={"small"}
                    ghost
                    onClick={() => setAddEdit({ visible: true, edit: itm })}
                    icon={<EditOutlined />}
                  >
                    Засах
                  </Button>
                )}
                {itm.status != "active" && (
                  <Popconfirm
                    placement="topLeft"
                    title={"Та устгахдаа итгэлтэй байна уу?"}
                    onConfirm={() => {
                      deleteItem(itm.id);
                    }}
                    okText="Тийм"
                    cancelText="Үгүй"
                  >
                    <Button
                      type={"primary"}
                      size={"small"}
                      danger
                      ghost
                      onClick={() => {}}
                      icon={<DeleteOutlined />}
                    >
                      Устгах
                    </Button>
                  </Popconfirm>
                )}
              </>
            ))}
          <Button
            onClick={() => setDetail({ visible: true, edit: itm.id })}
            type={"primary"}
            size={"small"}
            ghost
            icon={<FileTextOutlined />}
          >
            Дэлгэрэнгүй
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <BreadMe
        title={[
          "Зөвшөөрөл",
          "Ургамал хамгааллын бодис, бордооны жагсаалт, тоо хэмжээ",
        ]}
      />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row justify={"space-between"}>
          <Form
            layout="inline"
            onFinish={(data) => {
              if (data.year) {
                data.year = data.year.format("YYYY");
              }
              changeFilter({
                ...filter,
                ...data,
              });
            }}
          >
            <Form.Item name="chemicalTypeId">
              <Select
                allowClear
                style={{ width: 180 }}
                placeholder="Бодис, бордооны төрөл"
              >
                {typeList.result.map((t) => (
                  <Select.Option key={t.id} value={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="year">
              <DatePicker picker="year" placeholder="Илгээсэн он" />
            </Form.Item>
            <Form.Item name="status">
              <Select allowClear style={{ width: 180 }} placeholder="Төлөв">
                {Statuses.map((t) => (
                  <Select.Option key={t.id} value={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="organization">
              <Input placeholder={"Хуулийн этгээдийн регистр"} />
            </Form.Item>
            <Form.Item>
              <Button type={"primary"} htmlType="submit">
                <SearchOutlined />
              </Button>
            </Form.Item>
          </Form>
          <Space size={24}>
            {(license.import || license.sale) && (
              <Button
                onClick={() => setAddEdit({ visible: true })}
                type={"primary"}
              >
                <PlusSquareOutlined /> Бодис, бордооны зөвшөөрөл хүсэх
              </Button>
            )}
            {[USER_ROLE.SPECIALIST, USER_ROLE.ADMIN].includes(user.role.cd) && (
              <Button
                type="primary"
                disabled={selectedRows.length === 0}
                onClick={() => setVisibleDetails(true)}
              >
                Санал оруулах
              </Button>
            )}
          </Space>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Table
              rowSelection={
                [USER_ROLE.SPECIALIST, USER_ROLE.ADMIN].includes(user.role.cd)
                  ? {
                      selectedRowKeys,
                      type: "checkbox",
                      hideSelectAll: true,
                      getCheckboxProps: (record) => ({
                        disabled:
                          (selectedRows.length > 0 &&
                            !(
                              selectedRows.find(
                                (item) =>
                                  item.organization.id ===
                                  record.organization.id
                              ) &&
                              selectedRows.find(
                                (item) => item.status === record.status
                              )
                            )) ||
                          record.vAgro !== "pending",
                      }),
                      onChange: (rowKeys: React.Key[], rows: any[]) => {
                        setSelectedRowKeys(rowKeys);
                        setSelectedRows(rows);
                      },
                    }
                  : undefined
              }
              rowKey="id"
              columns={columns}
              dataSource={content.result}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
      <ChemicalLicenseDetail
        visible={detail.visible}
        id={detail.edit}
        onHide={() => {
          setDetail({ visible: false });
          loadContent();
        }}
      />
      {addEdit.visible && (
        <ChemicalAddEdit
          license={license}
          visible={addEdit.visible}
          edit={addEdit.edit}
          onHide={() => {
            setAddEdit({ visible: false });
            loadContent();
          }}
          onSuccess={() => {
            setAddEdit({ visible: false });
            loadContent();
          }}
        />
      )}

      {visibleDetails && (
        <ChemicalLicenseDetails
          ids={selectedRows.map((item) => item.id)}
          visible={visibleDetails}
          onHide={() => {
            setVisibleDetails(false);
            setSelectedRowKeys([]);
            setSelectedRows([]);
            loadContent();
          }}
        />
      )}
    </>
  );
};
interface DetailProps {
  id?: number;
  visible: boolean;
  onHide: any;
}
interface DetailState {
  loading: boolean;
  result?: any;
  resultCode?: number;
}
interface DataState {
  offerAmount: any;
  reason?: string;
}
const ChemicalLicenseDetail = ({ visible, onHide, id }: DetailProps) => {
  const { user } = useContext(UserContext);
  const [fileList] = useChemicalTypeFileList();
  const [data, changeData] = useState<DataState>({
    offerAmount: "",
    reason: "",
  });
  const [content, changeContent] = useState<DetailState>({
    loading: true,
    result: {},
    resultCode: 200,
  });
  useEffect(() => {
    if (visible) {
      loadContent();
    }
  }, [visible]);
  const submitLicense = useCallback(
    async (value: any) => {
      if (value.appointmentDate) {
        value.appointmentDate = value.appointmentDate.format("YYYY-MM-DD");
      }
      value.id = content.result?.license.id;
      let response = await NetworkApi.requestPost(
        "/api/chemical/license/success",
        value
      );
      if (response.resultCode == 200) {
        loadContent();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
      console.log(value);
    },
    [content]
  );
  const loadContent = useCallback(async () => {
    changeContent({
      loading: true,
    });
    let response = await NetworkApi.requestGet(
      `/api/chemical/license/detail/${id}`
    );

    changeContent({
      loading: false,
      result: response.result || {},
      resultCode: response.resultCode,
    });
  }, [id]);
  const setConfirm = useCallback(
    async (status: "accepted" | "rejected") => {
      let rq: any = {
        id: content.result?.license.id,
        status,
        ...data,
      };
      let response = await NetworkApi.requestPost(
        "/api/chemical/license/confirm",
        rq
      );
      if (response.resultCode == 200) {
        loadContent();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [content, data]
  );
  const files = useMemo(() => {
    if (content.result?.license?.files && fileList.result) {
      const filesObj = JSON.parse(content.result.license.files);
      const filesArr: any[] = [];
      for (const [key, value] of Object.entries<[key: string, value: any]>(
        filesObj
      )) {
        const typeFile = fileList.result.find(
          (item) => parseInt(item.id) === parseInt(key)
        );
        filesArr.push({
          ...value,
          typeFile,
        });
      }
      return filesArr;
    }
    return [];
  }, [content, fileList]);

  const showConfirmedUser = () => {
    const confirms = content.result?.license?.histories.filter(
      (h: any) => h.type === "confirmed"
    );
    if (confirms.length > 0) {
      return (
        <Row>
          <Col span={10}>Мэргэжилтэн:</Col>
          <Col
            span={14}
          >{`${confirms[0].user.lastName} ${confirms[0].user.firstName}`}</Col>
          <Col span={10}>Санал:</Col>
          <Col
            span={14}
          >{`${content.result?.license.offerAmount} ${content.result?.license.chemical.unit.name}`}</Col>
          {content.result?.license.appointmentNumber && (
            <>
              <Col span={10}>Тушаалын дугаар:</Col>
              <Col
                span={14}
              >{`${content.result?.license.appointmentNumber}`}</Col>
            </>
          )}
          <Col span={10}>Огноо:</Col>
          <Col span={14}>{`${moment(confirms[0].createdAt).format(
            "YYYY-MM-DD"
          )}`}</Col>
        </Row>
      );
    }
    return <></>;
  };

  const showSuccessedUser = () => {
    const successeds = content.result?.license?.histories.filter(
      (h: any) => h.type === "successed"
    );
    if (successeds.length > 0) {
      return (
        <Row>
          <Col span={10}>Хэлтсийн дарга:</Col>
          <Col
            span={14}
          >{`${successeds[0].user.lastName} ${successeds[0].user.firstName}`}</Col>
          <Col span={10}>Огноо:</Col>
          <Col span={14}>{`${moment(successeds[0].createdAt).format(
            "YYYY-MM-DD"
          )}`}</Col>
        </Row>
      );
    }
    return <></>;
  };
  return (
    <Modal
      width={900}
      centered
      visible={visible}
      onCancel={onHide}
      title={"Бодис, бордооны зөвшөөрлийн дэлгэрэнгүй"}
      footer={[
        <Button key="submit" type="default" onClick={onHide}>
          Хаах
        </Button>,
      ]}
    >
      <Row gutter={15}>
        <Col span={16}>Зөвшөөрлийн төрөл</Col>
        <Col span={8}>
          {content.result?.license?.type == "sale"
            ? "Худалдан борлуулах"
            : "Импортлох"}
        </Col>
        <Col span={16}>Байгууллагын регистрийн дугаар</Col>
        <Col span={8}>{content.result?.license?.organization?.rd}</Col>
        <Col span={16}>Байгууллагын нэр</Col>
        <Col span={8}>{content.result?.license?.organization?.name}</Col>
        <Col span={16}>Захирлын нэр</Col>
        <Col span={8}>{content.result?.license?.organization?.firstName}</Col>
        <Col span={16}>Утасны дугаар</Col>
        <Col span={8}></Col>
        <Col span={16}>Бодис, бордооны нэр төрөл</Col>
        <Col span={8}>{content.result?.license?.chemical?.type?.name}</Col>
        <Col span={16}>Бодис, бордооны нэр </Col>
        <Col span={8}>{content.result?.license?.chemical?.technicalName}</Col>
        <Col span={16}>Үйлчлэх бодис, бордооны нэр </Col>
        <Col span={8}>{content.result?.license?.chemical?.name}</Col>
        <Col span={16}>Олон улсын бүртгэлийн дугаар CAS</Col>
        <Col span={8}>
          {content.result?.license?.chemical?.registrationNumber}
        </Col>
        <Col span={16}>Хэмжээ</Col>
        <Col span={8}>
          {content.result?.license?.requestedAmount}
          {content.result?.license?.chemical?.unit?.name}
        </Col>
        <Col span={24}>
          {files.map((item) => (
            <Row key={item.typeFile.id}>
              <Col span={12}>{item.typeFile.name}</Col>
              <Col span={12}>
                [
                <a target="_blank" href={`/tmp/${item.tmp}`}>
                  {item.name}
                </a>
                ]
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <Row justify="space-around" align="middle" gutter={20}>
        <Col span={12}>
          <fieldset style={{ border: "1px solid gray", padding: 10 }}>
            <legend
              style={{ width: "auto", paddingLeft: 10, paddingRight: 10 }}
            >
              Мэргэжилтэн:
            </legend>
            <Row>
              <Col span={10}>Төлөв:</Col>
              <Col span={14}>
                <TagCreat cs={content.result?.license?.vAgro} />
              </Col>
            </Row>
            {/* {(user.role.cd == "SPECIALIST" || user.role.cd == "ADMIN") &&
              content.result?.license?.vAgro == "pending" && (
                <Form
                  style={{ paddingTop: 15 }}
                  labelAlign="left"
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                >
                  <Form.Item label="Санал">
                    <InputNumber
                      value={data.offerAmount}
                      onChange={(t) => changeData({ ...data, offerAmount: t })}
                      parser={(value) => value?.replace(",", ".") || ""}
                    />
                  </Form.Item>
                </Form>
              )} */}
            {content.result?.license?.vAgro &&
              content.result?.license?.vAgro === "accepted" && (
                <>{showConfirmedUser()}</>
              )}
            <Row>
              <Col span={10}>Тайлбар:</Col>
              <Col span={14}>{content.result?.license?.reason}</Col>
            </Row>
            {/* {content.result?.license?.vAgro == "pending" &&
            (user.role.cd == "SPECIALIST" || user.role.cd == "ADMIN") ? (
              <>
                <Space size={10}>
                  <Button type="primary" onClick={() => setConfirm("accepted")}>
                    Санал илгээх
                  </Button>
                  <Button type="primary" danger>
                    Татгалзах
                  </Button>
                </Space>
              </>
            ) : null} */}
          </fieldset>
        </Col>
        <Col span={12}>
          <fieldset style={{ border: "1px solid gray", padding: 10 }}>
            <legend
              style={{ width: "auto", paddingLeft: 10, paddingRight: 10 }}
            >
              Хэлтсийн дарга:
            </legend>
            <Row>
              <Col span={10}>Төлөв:</Col>
              <Col span={14}>
                <TagCreat cs={content.result?.license?.pAgro} />
              </Col>
              {content.result?.license?.pAgro == "pending" &&
              content.result?.license?.vAgro == "accepted" &&
              (user.role.cd == "HEAD_OF_OFFICE" || user.role.cd == "ADMIN") ? (
                <>
                  <Col span={10}>Санал:</Col>
                  <Col span={14}>
                    {content.result?.license?.offerAmount}
                    {content.result?.license?.chemical?.unit?.name}{" "}
                  </Col>
                </>
              ) : null}
            </Row>
            {content.result?.license?.vAgro &&
              content.result?.license?.pAgro === "accepted" && (
                <>{showSuccessedUser()}</>
              )}
            <Row>
              <Col span={10}>Тайлбар:</Col>
              <Col span={14}>{content.result?.license?.reason}</Col>
            </Row>
            {/* {content.result?.license?.pAgro == "pending" &&
            content.result?.license?.vAgro == "accepted" &&
            (user.role.cd == "HEAD_OF_OFFICE" || user.role.cd == "ADMIN") ? (
              <>
                <Space size={10}>
                  <Button type="primary" onClick={() => setConfirm("accepted")}>
                    Зөвшөөрөх
                  </Button>
                  <Button type="primary" danger>
                    Татгалзах
                  </Button>
                </Space>
              </>
            ) : null} */}
          </fieldset>
        </Col>
      </Row>
      {/* {content.result?.license?.pAgro == "accepted" &&
        content.result?.license?.vAgro == "accepted" &&
        content.result?.license?.status == "pending" &&
        (user.role.cd == "SPECIALIST" || user.role.cd == "ADMIN") && (
          <Form layout="inline" onFinish={submitLicense}>
            <Form.Item name="appointmentNumber" label="Албан бичгийн дугаар">
              <Input />
            </Form.Item>
            <Form.Item name="appointmentDate" label="огноо">
              <DatePicker />
            </Form.Item>
            <Form.Item name="offerAmount" label="Зөвшөөрсөн тоо хэмжээ">
              <p>{content.result?.license?.offerAmount}</p>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Зөвшөөрөх
              </Button>
            </Form.Item>
          </Form>
        )} */}
    </Modal>
  );
};

interface DetailsProps {
  ids: any[];
  visible: boolean;
  onHide: any;
}

const ChemicalLicenseDetails = ({ visible, onHide, ids }: DetailsProps) => {
  const [content, changeContent] = useState<DetailState>({
    loading: true,
    result: {},
    resultCode: 200,
  });
  const [detail, setDetail] = useState<any>({});
  const [chemicalRows, setChemicalRows] = useState<any[]>([]);
  useEffect(() => {
    if (visible) {
      loadContent();
    }
  }, [visible]);
  const loadContent = useCallback(async () => {
    changeContent({
      loading: true,
    });
    let response = await NetworkApi.requestPost(
      `/api/chemical/license/details`,
      { ids }
    );

    if (response.result.rows.length > 0) {
      const license = response.result.rows[0];
      setDetail({
        licenseType: license.type,
        organizationId: license.organization.rd,
        organizationName: license.organization.name,
        organizationCEOName: license.organization.firstName,
      });
    }

    const rows = response.result.rows.map((item: any) => ({
      ...item,
      offerAmount: item.requestedAmount,
    }));

    setChemicalRows(rows);

    changeContent({
      loading: false,
      result: response.result.rows || [],
      resultCode: response.resultCode,
    });
  }, [ids]);
  const columns = [
    {
      title: "Бодис, бордооны нэр төрөл",
      dataIndex: "chemical",
      render: (value: any) =>
        value?.type
          ? value?.type?.name == "sale"
            ? "Борлуулах"
            : "Импортлох"
          : "-",
    },
    {
      title: "Бодис, бордооны нэр",
      dataIndex: "chemical",
      render: (value: any) => value?.technicalName ?? "-",
    },
    {
      title: "Үйлчлэх бодис, бордооны нэр",
      dataIndex: "chemical",
      render: (value: any) => value?.name ?? "-",
    },
    {
      title: "Олон улсын бүртгэлийн дугаар CAS",
      dataIndex: "chemical",
      render: (value: any) => value?.registrationNumber ?? "-",
    },
    {
      title: "Хүсэлтийн хэмжээ",
      render: (row: any) =>
        row?.requestedAmount
          ? `${row.requestedAmount} ${row.chemical.unit.name}`
          : "-",
    },
    {
      title: "Санал",
      dataIndex: "offerAmount",
      editable: true,
    },
  ];
  const handleSaveCell = (rows: any[]) => {
    setChemicalRows(rows);
  };
  const handleRequestAgro = useCallback(
    async (status: "accepted" | "rejected") => {
      const offers = chemicalRows.map(({ id, offerAmount }) => ({
        id,
        offerAmount,
      }));
      let rq: any = {
        status,
        offers,
      };
      let response = await NetworkApi.requestPost(
        "/api/chemical/license/confirm",
        rq
      );
      if (response.resultCode === 200) {
        message.success("Хүсэлт амжилттай биелэгдлээ");
        onHide();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [chemicalRows, loadContent]
  );
  return (
    <Modal
      width={900}
      centered
      visible={visible}
      onCancel={onHide}
      title={"Бодис, бордооны зөвшөөрлийн дэлгэрэнгүй"}
      footer={null}
    >
      <Row gutter={15}>
        <Col span={16}>Зөвшөөрлийн төрөл</Col>
        <Col span={8}>
          {detail?.licenseType == "sale" ? "Худалдан борлуулах" : "Импортлох"}
        </Col>
        <Col span={16}>Байгууллагын регистрийн дугаар</Col>
        <Col span={8}>{detail?.organizationId}</Col>
        <Col span={16}>Байгууллагын нэр</Col>
        <Col span={8}>{detail?.organizationName}</Col>
        <Col span={16}>Захирлын нэр</Col>
        <Col span={8}>{detail?.organizationCEOName}</Col>
        <Col span={16}>Утасны дугаар</Col>
        <Col span={8}></Col>
      </Row>
      <Row style={{ marginTop: 24, marginBottom: 15 }}>
        <EditableChemicalLicenseTable
          loading={content.loading}
          columns={columns}
          dataSource={chemicalRows ?? []}
          handleSaveCell={handleSaveCell}
        />
      </Row>
      <Row justify="end">
        <Space size={10}>
          <Button type="primary" onClick={() => handleRequestAgro("accepted")}>
            Зөвшөөрөх
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => handleRequestAgro("rejected")}
          >
            Татгалзах
          </Button>
        </Space>
      </Row>
    </Modal>
  );
};

interface EditProps {
  visible: boolean;
  edit?: any;
  onHide: any;
  onSuccess: any;
  license: any;
}

const ChemicalAddEdit = ({
  visible,
  onHide,
  onSuccess,
  edit,
  license,
}: EditProps) => {
  const formRef = useRef<any>();
  const [typeList] = useChemicalTypeList();
  const [chemicalList] = useChemical();
  const [selectedChemicalType, changeSelectedChemicalType] = useState<any>("");
  const [selectedChemical, changeSelectedChemical] = useState<any>("");
  const [files, changeFiles] = useState<any>({});
  useEffect(() => {
    if (visible) {
      if (edit) {
        formRef.current?.setFieldsValue({
          ...edit,
          chemicalTypeId: edit.chemical.type.id,
        });
        changeFiles(JSON.parse(edit.files ?? "{}"));
      } else {
        formRef.current?.resetFields();
        changeFiles({});
      }
    }
  }, [visible, edit]);
  useEffect(() => {
    if (visible && edit && typeList.result) {
      const type = typeList.result.find(
        (item) => parseInt(item.id) === parseInt(edit.chemical.type.id)
      );
      changeSelectedChemicalType(type);
    }
  }, [visible, edit, typeList]);
  const chemical = useMemo(() => {
    return chemicalList.result.find((c) => c.id == selectedChemical);
  }, [selectedChemical, chemicalList]);
  const onFinish = useCallback(
    async (data: any) => {
      const allowed = selectedChemicalType.files.map((item: any) => item.id);
      const filesObj = Object.keys(files)
        .filter((key) => allowed.includes(+key))
        .reduce((obj: any, key) => {
          obj[key] = files[key];
          return obj;
        }, {});
      let rq = {
        ...data,
        files: filesObj,
        id: edit?.id,
      };
      let response = await NetworkApi.requestPost(
        "/api/chemical/license/request",
        rq
      );
      if (response.resultCode == 200) {
        onSuccess();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [files, edit, selectedChemicalType]
  );
  const uploadFile = useCallback((info: any, name: string) => {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      changeFiles((files: any) => {
        return {
          ...files,
          [name]: {
            name: info.file.name,
            tmp: info.file.response.result.name,
            type: info.file.response.result.mimetype,
          },
        };
      });
      message.success(`${info.file.name} файл амжилттай хууллаа.`);
    } else if (status === "error") {
      message.error(`${info.file.name} файл хуулхад алдаа гарлаа.`);
    }
  }, []);

  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = async (search?: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return;
      }
      let response = await NetworkApi.requestGet("/api/chemical/list", {
        name: search,
        ...(selectedChemicalType ? { typeId: selectedChemicalType.id } : {}),
      });

      if (response.resultCode === 200) {
        const newOptions = response.result?.rows.map((item: any) => ({
          label: item.technicalName,
          value: item.id,
        }));
        setOptions(newOptions);
        setFetching(false);
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
        setFetching(false);
      }
    };

    return debounce(loadOptions);
  }, [selectedChemicalType]);

  useEffect(() => {
    debounceFetcher();
  }, [selectedChemicalType]);

  return (
    <Modal
      width={794}
      centered
      visible={visible}
      onCancel={onHide}
      title={"Бодис, бордооны зөвшөөрлийн хүсэлт"}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => formRef.current?.submit()}
        >
          Хүсэлт илгээх
        </Button>,
      ]}
    >
      <Form onFinish={onFinish} ref={formRef} layout={"vertical"}>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              name="chemicalTypeId"
              label="Бодис, бордооны төрөл:"
              rules={[
                {
                  required: true,
                  message: "Бодис, бордооны төрөл сонгоно уу!",
                },
              ]}
            >
              <Select
                style={{ width: "80%" }}
                placeholder="Бодис, бордооны төрөл"
                onChange={(v, c) => {
                  changeSelectedChemicalType(
                    typeList.result.find((item) => item.id === v)
                  );
                  changeSelectedChemical("");
                  formRef.current?.setFieldsValue({ chemicalId: undefined });
                }}
              >
                {typeList.result.map((t) => (
                  <Option key={t.id} value={t.id}>
                    {t.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="chemicalId"
              label="Бодис, бордооны худалдааны нэр:"
              rules={[
                {
                  required: true,
                  message: "Бодис, бордооны худалдааны нэр сонгоно уу!",
                },
              ]}
            >
              <Select
                style={{ width: "80%" }}
                onChange={(v) => changeSelectedChemical(v)}
                filterOption={false}
                onSearch={debounceFetcher}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                options={options}
                showSearch
                placeholder="Бодис, бордооны худалдааны нэр"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="type"
              label="Зөвшөөрлийн төрөл:"
              rules={[
                {
                  required: true,
                  message: "Зөвшөөрлийн төрөл сонгоно уу!",
                },
              ]}
            >
              <Select style={{ width: "80%" }} placeholder="Зөвшөөрлийн төрөл">
                {license.sale && (
                  <Option value={"sale"}>Худалдан борлуулах</Option>
                )}
                {license.import && <Option value={"import"}>Импортлох</Option>}
                {license.import && license.sale && (
                  <Option value={"importsale"}>Импортлох борлуулах</Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="requestedAmount"
              label="Хэмжээ:"
              rules={[
                {
                  required: true,
                  message: "Хэмжээ оруулна уу",
                },
                () => ({
                  validator(rule, amount) {
                    if (typeof amount === "number" && amount <= 0) {
                      return Promise.reject("0-ээс дээш хэмжээ оруулна уу");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                style={{ width: "80%" }}
                addonAfter={chemical?.unit?.name ?? ""}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row style={{ marginBottom: 15 }}>
        <Col span={24}>
          <fieldset style={{ border: "1px solid gray", padding: 10 }}>
            <legend
              style={{ width: "auto", paddingLeft: 10, paddingRight: 10 }}
            >
              Бүрдүүлэх материалууд:
            </legend>
            {(selectedChemicalType?.files ?? []).map((item: any) => (
              <Col key={item.id} span={24}>
                {files[`${item.id}`] ? (
                  <Card bodyStyle={{ padding: 5 }}>
                    {files[`${item.id}`].type == "application/pdf" ? (
                      <>
                        <FilePdfOutlined />
                        {files[`${item.id}`].name}
                      </>
                    ) : (
                      <Image
                        width={40}
                        height={40}
                        src={`${config.get("apiUrl")}/tmp/${
                          files[`${item.id}`].tmp
                        }`}
                      />
                    )}
                    <DeleteOutlined
                      style={{
                        position: "absolute",
                        padding: 5,
                        right: 0,
                        top: 0,
                        background: "white",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const v = { ...files };
                        if (Object.keys(v).includes(`${item.id}`)) {
                          delete v[`${item.id}`];
                        }
                        changeFiles(v);
                      }}
                    />
                  </Card>
                ) : (
                  <Dragger
                    name="file"
                    showUploadList={false}
                    multiple={false}
                    action={`${host}/api/file/upoad`}
                    onChange={(info: any) => {
                      uploadFile(info, `${item.id}`);
                    }}
                  >
                    <Row>
                      <Col span={20}>
                        <p className="ant-upload-text">{item.name}</p>
                        <p className="ant-upload-hint">
                          Чирэх болон сонгож оруулна уу
                        </p>
                      </Col>
                      <Col span={4}>
                        <CloudDownloadOutlined />
                      </Col>
                    </Row>
                  </Dragger>
                )}
              </Col>
            ))}
          </fieldset>
        </Col>
      </Row>
    </Modal>
  );
};
export default Chemical;
